import React, { useMemo } from 'react';

import { graphql } from 'gatsby';
import styled from 'styled-components';

import PostsList from '@components/PostsList';
import { Blog as Layout } from '@layouts';
import { device } from '@styles';
import { ContentPost } from 'src/types';

const Header = styled.div`
    & > * {
        margin-bottom: 24px;
    }

    @media ${device.tablet} {
        padding-top: 50px;
    }
`;

type BlogProps = {
    data: {
        posts: {
            nodes: {
                frontmatter: ContentPost;
            }[];
        };
    };
};

const Blog: React.FC<BlogProps> = ({ data }) => {
    const posts: ContentPost[] = useMemo(
        () =>
            data.posts.nodes.map(
                (node: { frontmatter: ContentPost }) => node.frontmatter
            ),
        [data]
    );

    return (
        <Layout>
            <Header>
                <h1>Personal blog</h1>
                <p>
                    A handy way of storing my struggles and hopefully solutions.
                </p>
            </Header>
            <PostsList posts={posts} />
        </Layout>
    );
};

export const query = graphql`
    query PostsData($slug: [String]) {
        posts: allMdx(
            filter: {
                internal: { contentFilePath: { regex: "/posts/" } }
                frontmatter: { tags: { in: $slug } }
            }
            sort: { order: DESC, fields: frontmatter___date }
        ) {
            nodes {
                frontmatter {
                    date
                    description
                    slug
                    tags
                    name: title
                }
            }
        }
    }
`;

export default Blog;

export const Head = () => <title>Blog</title>;
